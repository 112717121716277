import React, { useContext } from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import SummerKindnessModalContent from "@src/components/partials/summer-kindness-series/SummerKindnessModalContent";
import window from "global/window";

function SummerKindnessSeriesWeek2() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "week2-small" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "chat-circle" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "thinking-circle" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2 } = data;
  const modalContext = useContext(ModalContext);

  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 2"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 2",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Find Your Inner Hero"
        headerSub="Kindness Habit 2:"
        headerText={
          <>
            Adopt caring values and <br />
            the drive to help others
          </>
        }
        headerImage={headerImage}
        whatisIt="What makes superheroes great? Okay, yes they can fly :) But what makes them SUPER is having a strong set of beliefs based on the idea of helping others. If kids see themselves as a hero-in-training, they’ll want to act like one. They’ll strive to be compassionate, generous, and thoughtful towards others. They’ll see that kindness is not just a choice, it’s an incredible and powerful part of who they are!"
        whyIsItImportant="Kids will act in ways that aligns with their character when faced with tricky or difficult situations. A phrase or motto that helps them remember their values often goes a long way — just ask Superman! Kids with an identity tied to kindness will be more likely to respond in a thoughtful way and consider everyone’s perspective — superhero cape optional ;)"
        habbits={[
          {
            header: "Celebrate character over actions:",
            text: "You are a considerate and helpful person! It’s great to point out good deeds, but it goes a long way to remind kids that being kind is part of who they are.",
          },
          {
            header: "Invite more helpers:",
            text: "Can you be my helper? Kids invited to be helpers choose to help more often because its reflects on their identity. Think of other words you can use to make actions personal and powerful!",
          },
          {
            header: "Secret questions:",
            text: "Let kids in on some secret questions they can ask themselves when feeling challenged or confused, like The Golden Rule: Would I want someone to do this to me? Or, The Assembly Test: Would I do this if the principal announced it to my school?",
          },
          {
            header: "Capture Caring Moments:",
            text: "Capture the moments on camera when kids choose to engage in kind and thoughtful acts. And, show them off!",
          },
        ]}
        activitiesText="Find Your Inner Hero at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Family Motto",
            text: "What does your family stand for?",
            onClick: () => {
              modalContext.showModal(ModalType.SummerKindnessModal, {
                content: (
                  <SummerKindnessModalContent
                    header1="Family Motto"
                    text1="What does your family stand for? Come together and get comfy, there's a lot to discuss! Watch the video for instructions!"
                    text2={
                      <>
                        Activity Type: Family Time, Craft + Create!
                        <br />
                        Play Time: 1 hr to discuss and create!
                      </>
                    }
                    header2="Find Your Inner Hero"
                    items={[
                      { youtubeUrl: "https://www.youtube.com/embed/25Rffo3c9Sc" },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Family-Motto.pdf",
                        text: "Activity Instructions",
                        icon: "pdf",
                      },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Family-Crest.pdf",
                        text: "Family Motto template",
                        icon: "template",
                      },
                    ]}
                  />
                ),
              });
            },
          },
          {
            icon: activity2,
            header: "Kindness Creation Challenge",
            text: "Do you have an idea for a new way to practice Reading Emotions? Adults, submit your family’s ideas for a chance to share it with the world!",
            onClick: () => {
              window.location.href = "https://www.surveymonkey.com/r/kindnesscreation";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek2;
